/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "~@angular/material/theming";

@include mat.core();

// https://trevier.medium.com/angular-material-theme-color-options-7d5968cb7460
$angular-primary: mat.define-palette($mat-blue, 500, 100, 900);
$angular-accent: mat.define-palette($mat-lime, A200, A100, A400);
$angular-warn: mat.define-palette($mat-pink);

$angular-default-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);

@include mat.all-component-themes($angular-default-theme);


$angular-primary: mat.define-palette($mat-indigo , 500, 100, 900);
$angular-accent: mat.define-palette($mat-teal, A200, A100, A400);
$angular-warn: mat.define-palette($mat-red);

$angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);

.darkMode {
  @include mat.all-component-colors($angular-dark-theme);
  @include mat.input-theme($angular-dark-theme); 
}

// Below here is common css for all pages
input[type=file]::file-selector-button {
  background-color: #2196f3;
  border-radius: 12px;
  border: 2px solid black;
  color: white;
}
.fileNamePos{
  position: absolute;
  left: 0;
  padding-left: 10px;
}
.hoverRow{
  cursor: pointer;
}

.announcements {
	border-radius: 1rem;
	margin: 5px 5px 30px 5px;
	border: 1px solid #000066;
    background-color: #ffffff; /* white */
	text-align: left;
	padding: 5px 5px 15px 5px;
	text-align: -webkit-center;
}

.announcehead {
	border-radius: 8px;
    font-size: 12pt;
	font-weight: bold;
	text-align: center;
	color: #ffffff; /* black */
	background-color: #000066; /* white */
	border-bottom: 3px solid #6699ff; /* light gray */
	margin: 0;
	padding: 4px;
}

.announcetitle {
    font-size: 10pt;
	font-weight: bold;
	text-align: center;
	// color: #000066; /* black */
	// background-color: #ffffff; /* white */
	padding: 4px 4px 0 4px;
	color: #ffffff;
    background-color: #6699ff !important;
	border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.announcetext,.announceurl {
    font-size: 9pt;
	font-weight: normal;
	text-align: left;
	color: #000000; /* black */
	background-color: #ffffff; /* white */
	padding-left: 4px;
	padding-right: 4px;
	font-weight: 600;
}

.announceurl {
	font-size: 9pt;
	text-align: left;
}

.announceurl a:link {
	color: #000000; /* black */
	text-decoration: underline;
	font-weight: normal;
}

.announcefooter {
    font-size: 9pt;
	font-weight: normal;
	text-align: left;
	color: #000000; /* black */
	background-color: #ffffff; /* white */
	padding: 1px 4px 4px 4px;
	margin: 0;
	font-weight: 600;
	border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.announcelist, .projectlist {
	margin-bottom: 3em;
    padding: 5px 0 .5em 0;
}

.announcelist table, .projectlist table {
	width: 85%;
	border: 1px solid #000000; /* black */
	display: table; /* not supported by IE */
}

.annBlock{
	// border-bottom-style: solid;
    // border-bottom-color: #6699ff;
    // border-left-style: solid;
    // border-left-color: #6699ff;
    // border-right-style: solid;
    // border-right-color: #6699ff;
	border: 2px solid white;
	width: 99%;
    margin-top: 5px;

	border-top-left-radius: 20px;
    border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.annBackGround{
	background-color: #e7e7e7;
}
.pageHeader {
    font-weight: bold;
    font-size: 140%;
    color: #2f4f4f; /* light gray */
	display: block;
    text-align: left;
}